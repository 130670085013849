<template>
    <div class="commonWorkLog-container">
        <div v-if="!followRecordList || !followRecordList.length">暂无记录</div>
        <div v-else class="record-container">
            <div v-for="(item, index) in followRecordList" :key="index" class="record-item">
                <div class="item-top">
                    <div class="item-icon"></div>
                    <span class="item-role"> {{ item.operationName || '--' }} </span>
                    <span v-if="item.time" class="item-date"> {{ item.time }} </span>
                    <span v-else-if="item.createTime" class="item-date"> {{ item.createTime }} </span>
                    <span v-else class="item-date"> -- </span>
                </div>
                <div v-if="item.type == 'setDelete'">
                    <div class="item-content">
                        <div class="item-detail">订单作废：{{item.value[0]['作废原因']}}</div>
                    </div>
                </div>
                <div v-else-if="item.type == 'dealWorkOrderTask'">
                    <div class="item-content">
                        <div class="item-detail" v-for="(itemP, indexP) in item.value" :key="indexP">
                            <div class="item-detail" v-if="typeof itemP == 'object'">
                                <div v-for="(val, key) in itemP" :key="key">{{key}}：{{val}}</div>
                            </div>
                            <div class="item-detail" v-else>{{itemP}}</div>
                        </div>
                    </div>
                </div>
                <div v-else-if="item.type == 'earlySubtotal'">
                    <div class="item-content">
                        <div class="item-detail">工作小计：{{item.value.subtotal}}</div>
                        <div v-if="item.value.subtotalFileName">
                            <div v-for="(itemName, index) in item.value.subtotalFileName.split(',')" :key="index">
                                <div class="item-detail download-container">
                                    <common-picture :fileUrl="item.value.subtotalFile.split(',')[index]" :fileType="Config.fileType.imgType.includes(itemName.split('.')[itemName.split('.').length - 1]) ? 'imgText' : 'file'" :fileName="itemName"></common-picture>
                                    <div class="downloadBtn" @click="() => { downLoad(item.value.subtotalFile).split(',')[index] }">下载</div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div v-else-if="item.type == 'earlyRead'">
                    <div class="item-content">
                        <div class="item-detail">查看预警详情</div>
                    </div>
                </div>
                <div v-else-if="item.type == 'earlyRemark'">
                    <div class="item-content">
                        <div v-if="item.value.remarkType == 'full'" class="item-detail">咨询量爆量</div>
                        <div v-if="item.value.remarkType == 'personal'" class="item-detail">个人原因</div>
                        <div v-if="item.value.remarkType == 'unusual'" class="item-detail">软件原因</div>
                        <div class="item-detail">超时说明： {{item.value.remark}}</div>
                        <div v-if="item.value.fileName">
                            <div v-for="(itemName, index) in item.value.fileName.split(',')" :key="index">
                                <div class="item-detail download-container">
                                    <common-picture :fileUrl="item.value.fileUrl.split(',')[index]" :fileType="Config.fileType.imgType.includes(itemName.split('.')[itemName.split('.').length - 1]) ? 'imgText' : 'file'" :fileName="itemName"></common-picture>
                                    <div class="downloadBtn" @click="() => { downLoad(item.value.fileUrl.split(',')[index]) }">下载</div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div v-else-if="item.type == 'adoptAppeal'">
                    <div class="item-content">
                        <div class="item-detail">同意申诉</div>
                        <div class="item-detail">备注： {{item.value.remark}}</div>
                    </div>
                </div>
                <div v-else-if="item.type == 'failAppeal'">
                    <div class="item-content">
                        <div class="item-detail">拒绝申诉</div>
                        <div class="item-detail">备注： {{item.value.remark}}</div>
                    </div>
                </div>
                <div v-else-if="item.type == 'addAppeal'">
                    <div class="item-content">
                        <div class="item-detail">新增申诉</div>
                        <div class="item-detail">备注： {{item.value.appealContent}}</div>
                    </div>
                </div>
                <div v-else-if="item.type == 'updateAppeal'">
                    <div class="item-content">
                        <div class="item-detail">编辑申诉</div>
                        <div class="item-detail">操作：编辑申诉 {{item.value.remark}}</div>
                    </div>
                </div>
                <div v-else-if="item.type == 'discardAppeal'">
                    <div class="item-content">
                        <div class="item-detail">废弃申诉</div>
                        <div class="item-detail">备注： {{item.value.deleteReason}}</div>
                    </div>
                </div>
                <div v-else-if="item.type == 'confirmReceipt'">
                    <div class="item-content">
                        <div class="item-detail">进账信息</div>
                        <div class="item-detail">操作： 确认到账</div>
                    </div>
                </div>
                <div v-else-if="item.type == 'voidedCollection'">
                    <div class="item-content">
                        <div class="item-detail">作废记录</div>
                        <div class="item-detail">操作： 作废 </div>
                        <div class="item-detail">备注： <span style="display: inline-block;white-space: pre-line;vertical-align: top;">{{item.value[1].remark}}</span> </div>
                    </div>
                </div>
                <div v-else-if="item.type == 'earlyUpload'">
                    <div class="item-content">
                        <div class="item-detail">附件</div>
                        <div v-for="(itemName, index) in item.value.fileName.split(',')" :key="index">
                            <div class="item-detail download-container">
                                <common-picture :fileUrl="item.value.fileUrl.split(',')[index]" :fileType="Config.fileType.imgType.includes(itemName.split('.')[itemName.split('.').length - 1]) ? 'imgText' : 'file'" :fileName="itemName"></common-picture>
                                <div class="downloadBtn" @click="() => { downLoad(item.value.fileUrl).split(',')[index] }">下载</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="item.type == 'file'">
                    <div class="item-content">
                        <div class="item-detail">{{item.value[0]}}</div>
                        <div class="item-detail">{{item.value[1]}}</div>
                        <div class="item-detail download-container">
                            <common-picture :fileUrl="item.value[3].fileUrl" :fileType="Config.fileType.imgType.includes(item.value[3].fileUrl.split('.')[item.value[3].fileUrl.split('.').length - 1]) ? 'img' : 'file'" :fileName="item.value[2].fileName"></common-picture>
                            <div class="downloadBtn" @click="() => { downLoad(item.value[3].fileUrl) }">下载</div>
                        </div>
                    </div>
                </div>
                <div v-else-if="item.type == 'deleteFinancialFile'">
                    <div class="item-content">
                        <div class="item-detail">删除附件</div>
                        <div class="item-detail download-container">
                            <common-picture :fileUrl="item.value.fileUrl" :fileType="Config.fileType.imgType.includes(item.value.fileUrl.split('.')[item.value.fileUrl.split('.').length - 1]) ? 'img' : 'file'" :fileName="item.value.fileName"></common-picture>
                            <div class="downloadBtn" @click="() => { downLoad(item.value.fileUrl) }">下载</div>
                        </div>
                    </div>
                </div>
                <div v-else-if="item.type == 'initiateCollection'">
                    <div class="item-content">
                        <div class="item-detail">发起收款</div>
                        <div class="item-detail">实收金额： {{item.value[1].amount}}</div>
                        <div class="item-detail">实收对应时间： {{DateTransform(item.value[1].serviceStartTime)}} ~ {{DateTransform(item.value[1].serviceEndTime)}} （{{item.value[1].effectiveDays || '0天'}} ）</div>
                        <div class="item-detail">通知人： {{item.value[1].noticeName}}</div>
                        <div class="item-detail">备注：<span style="display: inline-block;white-space: pre-line;vertical-align: top;">{{item.value[1].remark}}</span> </div>
                        <div v-for="(itemFile, key) in item.value[1].fileUrls" :key="key" class="item-detail download-container">
                            <common-picture :fileUrl="itemFile.fileUrl" :fileType="itemFile.fileType" :fileName="itemFile.fileName"></common-picture>
                            <div class="downloadBtn" @click="() => { downLoad(itemFile.fileUrl) }">下载</div>
                        </div>
                        <div class="item-detail" v-if="item.value[1].invoiceName">开票信息</div>
                        <div v-if="item.value[1].invoiceName" style="border-left: 4px solid #1890FF;padding-left: 14px">
                            <div class="item-detail">发票类型： {{item.value[1].invoiceType ? item.value[1].invoiceType == 'common' ? '普通' : '专票' : '--'}}</div>
                            <div class="item-detail">名称： {{item.value[1].invoiceName || "--"}}</div>
                            <div class="item-detail">纳税人识别号： {{item.value[1].invoiceTaxpayerIdentificationNumber || "--"}}</div>
                            <div class="item-detail">地址、电话： {{item.value[1].invoicePhone || "--"}}</div>
                            <div class="item-detail">开户行及账号： {{item.value[1].invoiceAccount || "--"}}</div>
                        </div>
                        <div v-if="item.value[1].invoiceName" class="item-detail">收件信息</div>
                        <div v-if="item.value[1].invoiceName" style="border-left: 4px solid #1890FF;padding-left: 14px">
                            <div class="item-detail">收件人：{{item.value[1].transactionName || "--"}}</div>
                            <div class="item-detail">收件人联系方式：{{item.value[1].transactionPhone || "--"}}</div>
                            <div class="item-detail">收件人地址：{{item.value[1].invoiceAddress || "--"}}</div>
                        </div>
                    </div>
                </div>
                <div v-else-if="item.type == 'updateFile'">
                    <div class="item-content">
                        <div class="item-detail">修改收款附件</div>
                        <div v-for="(itemFile, key) in item.value[0].fileUrls" :key="key" class="item-detail">
                            <div class="item-detail download-container">
                                <common-picture :fileUrl="itemFile.fileUrl" :fileType="itemFile.fileType" :fileName="itemFile.fileName"></common-picture>
                                <div class="downloadBtn" @click="() => { downLoad(itemFile.fileUrl) }">下载</div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div v-else-if="item.type == 'uploadFile'">
                    <div class="item-content">
                        <div class="item-detail">上传附件</div>
                        <div v-for="(itemFile, key) in item.value.fileUrls" :key="key" class="item-detail">
                            <div class="item-detail download-container">
                                <common-picture :fileUrl="itemFile.fileUrl" :fileType="Config.fileType.imgType.includes(itemFile.fileUrl.split('.')[itemFile.fileUrl.split('.').length - 1]) ? 'img' : 'file'" :fileName="itemFile.fileName"></common-picture>
                                <div class="downloadBtn" @click="() => { downLoad(itemFile.fileUrl) }">下载</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="item.type == 'deleteDistributeFile'">
                    <div class="item-content">
                        <div class="item-detail">删除附件</div>
                        <div class="item-detail download-container">
                            <common-picture :fileUrl="item.value[1]['下载链接']" :fileType="Config.fileType.imgType.includes(item.value[1]['下载链接'].split('.')[item.value[1]['下载链接'].split('.').length - 1]) ? 'img' : 'file'" :fileName="item.value[0]['附件名称']"></common-picture>
                            <div class="downloadBtn" @click="() => { downLoad(item.value[1]['下载链接']) }">下载</div>
                        </div>
                    </div>
                </div>
                <div v-else-if="item.type == 'uploadDistributeFile'">
                    <div class="item-content">
                        <div class="item-detail">上传附件</div>
                        <div class="item-detail download-container">
                            <common-picture :fileUrl="item.value[1]['下载链接']" :fileType="Config.fileType.imgType.includes(item.value[1]['下载链接'].split('.')[item.value[1]['下载链接'].split('.').length - 1]) ? 'img' : 'file'" :fileName="item.value[0]['附件名称']"></common-picture>
                            <div class="downloadBtn" @click="() => { downLoad(item.value[1]['下载链接']) }">下载</div>
                        </div>
                    </div>
                </div>
                <div v-else-if="item.type == 'createIncome'">
                    <div class="item-content">
                        <div class="item-detail">新增出款</div>
                        <div class="item-detail">出账金额： {{ item.value.amount || "--" }}</div>
                        <div class="item-detail">出款时间： {{ DateTransform(item.value.recordTime) || "--" }}</div>
                        <div class="item-detail">通知人： {{ item.value.noticeName || "--" }}</div>
                        <div class="item-detail">备注：<span style="display: inline-block;white-space: pre-line;vertical-align: top;">{{ item.value.remark || "--" }}</span></div>
                        <div v-for="(itemFile, key) in item.value.fileUrls" :key="key" class="item-detail">
                            <div class="item-detail download-container">
                                 <common-picture :fileUrl="itemFile.fileUrl" :fileType="Config.fileType.imgType.includes(itemFile.fileUrl.split('.')[itemFile.fileUrl.split('.').length - 1]) ? 'img' : 'file'" :fileName="itemFile.fileName"></common-picture>
                                <div class="downloadBtn" @click="() => { downLoad(itemFile.fileUrl) }">下载</div>
                            </div>
                        </div>
                        <div class="item-detail">收款人： {{ item.value.transactionName || "--" }}</div>
                        <div class="item-detail">
                            收款方式：{{item.value.recordType
                            ? item.value.recordType == "bankCard" ? "银行卡"
                                : item.value.recordType == "alipay" ? "支付宝"
                                : item.value.recordType == "wechat" ? "微信"
                                : item.value.recordType == "cash" ? "现金" 
                                : item.value.recordType == "other" ? "其他" 
                                : item.recordType == "corporateAccount" ? "对公账户"
                                : item.recordType == "generalAccount" ? "对公账户（总）"
                                : item.recordType == "publicAccount" ? "对公账户（分）"
                                : item.recordType == "taobao" ? "淘宝"
                                : item.recordType == "tiktok" ? "抖音"
                                : item.recordType == "pinduoduo" ? "拼多多"
                                : "其他" 
                                : "--"}}
                        </div>
                        <div class="item-detail">收款账户： {{ item.value.transactionAccount || "--" }}</div>
                        <div class="item-detail">联系方式： {{ item.value.transactionPhone || "--" }}</div>
                    </div>
                </div>
                <div v-else-if="item.value && item.value.length">
                    <div v-for="(twoItem, twoIndex) in item.value" :key="twoIndex" class="item-content">
                        <div v-if="twoIndex == 0" class="item-detail">{{twoItem || '--'}}</div>
                        <div v-else>
                            <div v-for="(val, key) in twoItem" :key="key" class="item-detail">
                                <div v-if=" key != '跟进文件' && key != '文件' && key != '反馈文件' && key != '下载链接' && key != '文件地址'">
                                    {{key}}: 
                                    <span v-if="val && typeof val == 'string' && val.indexOf('店铺logo:') < 0" style="display: inline-block;white-space: pre-line;vertical-align: top;">{{val}}</span>
                                    <common-picture v-else-if="val && typeof val == 'string' && val.split('店铺logo:')[1]" :fileUrl="val.split('店铺logo:')[1]" :fileType="Config.fileType.imgType.includes(val.split('.')[val.split('.').length - 1]) ? 'img' : 'file'" fileName="店铺logo"></common-picture>
                                   <span v-else>--</span>
                                </div>
                                <div v-else-if="item.value[0] == '删除合同' && (key == '下载链接' || key == '文件地址')" class="download-container">
                                    <common-picture :fileUrl="val" :fileType="Config.fileType.imgType.includes(val.split('.')[val.split('.').length - 1]) ? 'img' : 'file'" :fileName="item.value[1]['删除合同名称']"></common-picture>
                                    <div class="downloadBtn" @click="() => { downLoad(val) }">下载</div>
                                </div>
                                <div v-else-if="item.value[0] == '上传合同' && (key == '下载链接' || key == '文件地址')" class="download-container">
                                    <common-picture :fileUrl="val" :fileType="Config.fileType.imgType.includes(val.split('.')[val.split('.').length - 1]) ? 'img' : 'file'" :fileName="item.value[1]['合同名称']"></common-picture>
                                    <div class="downloadBtn" @click="() => { downLoad(val) }">下载</div>
                                </div>
                                <div v-else-if="key == '下载链接' || key == '文件地址'" class="download-container">
                                    <common-picture :fileUrl="val" :fileType="Config.fileType.imgType.includes(val.split('.')[val.split('.').length - 1]) ? 'img' : 'file'" :fileName="val"></common-picture>
                                    <div class="downloadBtn" @click="() => { downLoad(val) }">下载</div>
                                </div>
                                <div v-else-if="key == '跟进文件' || key == '反馈文件' || key == '文件'">
                                    <div v-for="(fileItem, fileIndex) in val" :key="fileIndex">
                                        <div class="item-detail download-container">
                                            <common-picture :fileUrl="fileItem['下载链接']" :fileType="fileItem['文件类型'] == '图片' ? 'img' : 'file'" :fileName="fileItem['文件名称']"></common-picture>
                                            <div class="downloadBtn" @click="() => { downLoad(fileItem['下载链接']) }">下载</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <br />
                <br />
            </div>
        </div>
    </div>
</template>
<script>
import { DateTransform, Config } from "../../utils/index.js"
import CommonPicture from "./commonPicture.vue"
export default {
    components: { CommonPicture },
    props: ["followRecordList"],
    name: 'commonWorkLog',
    data() {
        return {
            Config,
            DateTransform
        }
    },
    methods: {
        downLoad(url) { // 下载文件
            // window.location.href = url;
            window.open(url)
        },
    }
}
</script>
<style lang="less" scoped>
.commonWorkLog-container {
    flex: 1;
    overflow: hidden;
    .record-container {
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        .record-item {
            margin-top: 16px;
            border-top: 1px #eaeaeb dashed;
            padding-top: 16px;
            .fileNames {
                color: #1890ff;
                margin-top: 8px;
                word-break: break-all;
                cursor: pointer;
            }
            .fileImg {
                width: 80px;
                margin-top: 8px;
                cursor: pointer;
            }
            .item-content {
                margin-left: 22px;
                .itemBtn {
                    display: inline-block;
                    height: 32px;
                    line-height: 32px;
                    padding: 0 14px;
                    border: 1px solid #1890ff;
                    border-radius: 4px;
                    font-size: 14px;
                    color: #101010;
                    margin: 16px 16px 8px 0;
                }
                .item-detail {
                    color: #333;
                    margin-top: 8px;
                    vertical-align: middle;
                    .visitIcon {
                    width: 14px;
                    margin-left: 8px;
                    }
                }
                .download-container {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    padding-right: 16px;
                    .downloadBtn {
                        color: #1890ff;
                        cursor: pointer;
                        font-size: 12px;
                    }
                }
            }
            .item-top {
                padding-right: 16px;
                .item-icon {
                    display: inline-block;
                    width: 4px;
                    height: 4px;
                    background-color: #fff;
                    border: 4px #1890ff solid;
                    border-radius: 50%;
                    margin-right: 8px;
                }
                .item-role {
                    color: #1890ff;
                }
                .item-date {
                    float: right;
                    color: #999;
                }
            }
        }
    }
}
</style>