<template>
    <div class="commonPicture-container">
        <el-image v-if="fileType == 'img'" class="fileImg" :src="fileUrl" :preview-src-list="[fileUrl]"></el-image>
        <div v-else-if="fileType == 'imgText'" class="imgText-content">
            <div class="fileNames"><img style="margin-right: 8px;width: 18px;height: 18px" :src="fileUrl" />{{fileName.length > 20 ? (fileName.slice(0, 9) + '...' + fileName.slice(fileName.length - 9, fileName.length)) : fileName}}</div>
            <el-image class="imgOpcity" :src="fileUrl" :preview-src-list="[fileUrl]"></el-image>
        </div>
        <div v-else @click="() => downLoad(fileUrl)" class="fileNames"><img style="margin-right: 8px;width: 18px;" src="../../assets/images/files.svg" />{{fileName.length > 20 ? (fileName.slice(0, 9) + '...' + fileName.slice(fileName.length - 9, fileName.length)) : fileName}}</div>
    </div>
</template>
<script>
import { Config } from "../../utils/index.js"
export default {
    props: ['fileUrl', 'fileType', 'fileName'],
    methods: {
        downLoad(url) { // 下载文件
            let urlType = url.split('.')[url.split('.').length - 1]
            if (Config.fileType.pdfType.includes(urlType)) {
                window.open(url)
            } else if (Config.fileType.PWEIType.includes(urlType)) {
                window.open(`https://view.officeapps.live.com/op/view.aspx?src=${url}`)
            }
            console.log(url, this.fileType)
            // window.location.href = url;
        },
    }
}
</script>
<style lang="less" scoped>
.commonPicture-container {
    .imgText-content {
        position: relative;
        .imgOpcity {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 999;
            width: 100%;
            height: 100%;
            /deep/.el-image__preview {
                opacity: 0;
            }
        }
    }
    .fileNames {
        color: #1890ff;
        // margin-top: 8px;
        word-break: break-all;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .fileImg {
        width: 80px;
        margin-top: 8px;
        cursor: pointer;
    }
     /deep/ .el-image__error{
         opacity: 0;
     }
    /deep/ .el-image-viewer__close {
        background: #606266;
        border-radius: 50%;
        .el-icon-circle-close:before {
            font-size: 20px;
            color: #fff;
            content: "\e6db";
        }
    }
}
</style>